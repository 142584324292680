import React from 'react';

import WebBanner from '../../public/img/WebBanner.webp';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import AboutPage from './about';
import HomePage from './home'
import '../../public/css/index.css'
import NavbarItem from '../NavbarItem';
import MenuPage from './menus';
import CateringPage from './catering';
import ShopPage from './shop';
import MapPage from './map';
import ContactPage from './contact';
import PageNotFound from './404';

class IndexPage extends React.Component {
  render() {
    return (
      <div>
      <div id="websiteBody" className="flex-column">
        <div id="websiteContent">
          <a href="/home">
            <div id="webBanner" className="flex-column">
              <img src={WebBanner} alt="Mint Cafe Web Banner"></img>
            </div>
          </a>
          <div className="navbar">
            <NavbarItem text="Home" to='/' />
            {/* <NavbarItem text="About Us" to='/about' /> */}
            <NavbarItem text="Restaurant Menus" to="/menus" />
            <NavbarItem text="Catering Menus" to="/catering" />
            <NavbarItem text="Gift Cards" to="/shop" />
            <NavbarItem text="Map It!" to="/location" />
            <NavbarItem text="Contact Us" to="/contact" />
          </div>
          <div className="wrapper-main">

            <div className="wrapper-content">
              <Router>
                <Switch>
                  <Redirect exact from="/" to="/home" />
                  <Redirect from="/contactus" to="/contact" />
                  <Route path="/home">
                    <HomePage />
                  </Route>
                  <Route path="/about">
                    <AboutPage />
                  </Route>
                  <Route path="/menus">
                    <MenuPage />
                  </Route>
                  <Route path="/catering">
                    <CateringPage />
                  </Route>
                  <Route path="/shop">
                    <ShopPage />
                  </Route>
                  <Route path="/location">
                    <MapPage />
                  </Route>
                  <Route path="/contact">
                    <ContactPage />
                  </Route>
                  <Route path="*">
                    <PageNotFound />
                  </Route>
                </Switch>
              </Router>
            </div>
          </div>
        </div>
      </div>
      <div id="copyright">&copy; 2021 The Mint Café, Inc.</div>
      </div>
    );
  }
}
export default IndexPage;
