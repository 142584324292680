import React, { Component } from 'react';
import moment from 'moment';
import * as constants from '../../constants';
import '../../public/css/menus.css';
import DinnerMenu from '../../public/assets/menus/house/dinnermenu.pdf';
import BreakfastMenu from '../../public/assets/menus/house/breakfastmenu.pdf';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { MetaTags } from 'react-meta-tags';


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
class MenuPage extends Component {

  constructor() {
    super();
    this.switchMenuType = this.switchMenuType.bind(this);

    const currentTime = moment();
    const lunchStart = new moment();
    const lunchEnd = new moment();
    lunchStart.set({ hour: 10, minute: 55, second: 0, millisecond: 0 });
    lunchEnd.set({ hour: 23, minute: 0, second: 0, millisecond: 0 });
    const isBkfst = (currentTime >= lunchStart) && (currentTime <= lunchEnd) ? false : true;

    this.state = {
      isBreakfast: isBkfst,
      currentMenu: (isBkfst === true) ? BreakfastMenu : DinnerMenu
    };

  }

  render() {
    const menuString = this.state.currentMenu === BreakfastMenu ? constants.breakfastMenu : constants.dinnerMenu;
    const otherMenu = (menuString === constants.breakfastMenu) ? constants.dinnerMenu : constants.breakfastMenu;

    return (
      <div className="page">
        <MetaTags>
          <title>Menus - The Mint Café</title>
          <meta property="og:title" content="Menus - The Mint Café" />
          <meta property="og:description" content="Lunch and dinner menus for the Mint Cafe in Wausau, WI" />
          <meta name="description" content="Lunch and dinner menus for the Mint Cafe in Wausau, WI" />
          <meta name="keywords" content="menu,restaurant,Wausau,eat,lunch,breakfast,dinner,business,events,casual,dining" />
          <meta name="googlebot" content="index, follow" />
          <link rel="canonical" content="https://www.mintcafewausau.com/menus" />
        </MetaTags>
        <span className="page-title">This is our {menuString}</span>
        <br />
        <span>Click here to view our <font id="menuSwitch" onClick={this.switchMenuType} tabIndex="1">
          {otherMenu}</font>
        </span>
        <br />
        <Document file={this.state.currentMenu}>
          <Page pageNumber={1} />
          <Page pageNumber={2} />
        </Document>
      </div>
    );
  }

  switchMenuType() {
    let doSwitch = false;
    let _currentMenu = this.state.currentMenu;
    let _isBreakfast = this.state.isBreakfast;
    if (
      (_isBreakfast && (_currentMenu === DinnerMenu)) ||
      (!_isBreakfast && (_currentMenu === BreakfastMenu))
    ) {
      doSwitch = true;
    } else {
      doSwitch = window.confirm(_isBreakfast ? constants.confirmDinner : constants.confirmBreakfast);
    }
    if (doSwitch) {
      let _menuType = (_currentMenu === BreakfastMenu) ? DinnerMenu : BreakfastMenu;
      this.setState({ currentMenu: _menuType });
    }

  }
}

export default MenuPage;