import React from 'react';
import '../../public/css/contact.css';
import { contactform } from '../../constants';
import { Address } from '../../constants';
import { RestaurantPhone } from '../../constants';
import $, { event } from "jquery";
import { MetaTags } from 'react-meta-tags';

class ContactPage extends React.Component {

  constructor() {
    super();
    this.submitToAPI = this.submitToAPI.bind(this);

    this.state = {};

  }

  render() {
    return (
      <div className="page">
        <MetaTags>
          <title>Contact Us - The Mint Café</title>
          <meta property="og:title" content="Contact Us - The Mint Cafe" />
          <meta property="og:description" content="Have a question that you can't find the answer to? Reach out to us through our contact form" />
          <meta name="description" content="Have a question that you can't find the answer to? Reach out to us through our contact form" />
          <meta name="keywords" content="contact,restaurant,Wausau,eat,cater,wedding,lunch,breakfast,dinner,business,catering,events" />
          <meta name="googlebot" content="index, follow" />
        </MetaTags>
        <div id="wrapper-main" className="flex-row">
          <div id="contact-form" className="flex-column">
            <form id="contact-us" className="flex-column" method="POST" onSubmit={(event) => this.submitToAPI(event)}>
              <label for="firstname">{contactform.firstname}</label>
              <input id="firstname" name="firstname" type="text" required={true} />
              <label for="lastname">{contactform.lastname}</label>
              <input id="lastname" name="lastname" type="text" required={true} />
              <label for="email">{contactform.email}</label>
              <input id="email" name="email" type="email" placeholder="your.email@mail.com" required={true} />
              <label for="phone">{contactform.phone}</label>
              <input id="phone" name="phone" type="tel" placeholder="123-456-7890" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
              <label for="message">{contactform.message}</label>
              <textarea id="message" name="message" rows={4} required={true} />
              <input id="nofill" name="nofill" type="checkbox" />
              <input id="submit" name="submit" type="submit" />
              <link rel="canonical" content="https://www.mintcafewausau.com/contact" />
            </form>
          </div>
          <div id="location-view" className="flex-column">
            <div id="address" className="flex-column">
              <div className="bold">{Address.business}</div>
              <div>{Address.street}</div>
              <div>{Address.city + ", " + Address.state}&nbsp;{Address.zip}</div>
              <div>Ph:&nbsp;{RestaurantPhone}</div>
            </div>
            <div id="map">
              <iframe title="Google Map" loading="lazy" scroll="auto" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0k3k0IBXOG0aZ7GR58rPfxO6nRLeWoIY&amp;q=422+3rd+St+Wausau+WI+54403" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  submitToAPI(e) {
    e.preventDefault()
    var URL = "https://35nf8lkbhb.execute-api.us-east-2.amazonaws.com/Prod/contact";

    var firstname = $("#firstname").val();
    var lastname = $("#lastname").val();
    var email = $("#email").val();
    var phone = $("#phone").val();
    var message = $("#message").val();
    var data = {
      firstname: firstname,
      lastname: lastname,
      email: email,
      phone: phone,
      message: message
    };
    console.log(data)

    $.ajax({
      type: "POST",
      url: URL,
      dataType: "json",
      crossDomain: "true",
      contentType: "application/json; charset=utf-8",
      data: JSON.stringify(data),


      success: function () {
        // clear form and show a success message        
        document.getElementById("contact-us").reset();
        alert(contactform.success);
      },
      error: function () {
        // show an error message
        alert(contactform.failure);
      }
    });

  }

}

export default ContactPage;