import React from 'react';

class AboutPage extends React.Component {
    render() {
    return(
        <div tabIndex="1" className="page">
          <h1>The About page goes here</h1>
        </div>
    );
  }
}

export default AboutPage;