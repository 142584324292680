import React from 'react';
import * as constants from '../constants.js'

const getHoursText = (day) => {
  return ((constants.hours[day] === null) || (constants.hours[day] === "") || (constants.hours[day] === undefined)) ? constants.hours["Default"] : constants.hours[day];
}

class HoursEntry extends React.Component {
  render() {
    return (
      <tr className="hours-entry">
        <td className = "hours-entry-day">
          {this.props.day}:
        </td>
        <td className="hours-entry-times">
          {getHoursText(this.props.day)}
        </td>
      </tr>
    );
  }
}

export default HoursEntry;