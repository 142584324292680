import React from 'react';

class MenuEntry extends React.Component {
  render() {
    return (
      <div className="menu-entry">
        <a href={this.props.to} target="_blank" rel="noopener noreferrer">
          <table>
            <tbody>
              <tr>
                <td>
                  <img className="menuimg" alt="An example page from the menu" src={this.props.img} />
                </td>
                <td>
                  <table className="MenuText">
                    <tbody>
                      <tr>
                        <td>
                          <span className="menu-entry-title">{this.props.title}</span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <span className="menu-entry-text">{this.props.text}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            </tbody>
          </table>
        </a>
      </div>
    );
  }
}

export default MenuEntry;