import React from 'react';

class NavbarItem extends React.Component {
    render() {
    return(
        <div className="navitem">
          <a href={this.props.to} tabIndex="0" >{this.props.text}</a>
        </div>
    );
  }
}

export default NavbarItem;