import React from 'react';
import '../../public/css/404.css';
import { MetaTags } from 'react-meta-tags';

class PageNotFound extends React.Component {
  render() {
    return (
      <div className="page">
        <MetaTags>
          <meta httpEquiv="refresh" content="15;url=/home" />
          <title>Page not found</title>
          <meta property="og:title" content="Page not found" />
          <meta property="og:description" content="The content you requested could not be found" />
          <meta name="description" content="The content you requested could not be found" />
          <meta name="keywords" content="The content you requested could not be found" />
          <meta name="robots" content="noindex, follow" />
          <meta name="googlebot" content="noindex, follow" />
        </MetaTags>
        <div align="center">
          <h3 align="center" id="404_title">The requested resource was not found!</h3>
          <p></p>
          <p><strong><span>We&#39;re sorry, but the page you were trying to reach is not available.</span>
            <br />
            <span>It may have been removed, renamed, or it may be a technical error.</span>
            <br />
            <span>We really don&#39;t want to fire our technician, so heres a button back</span>
            <br />
            <span>to the home page.</span></strong>
          </p>
          <a href="/home">
            <button id="homeButton" type="button">Back to Home</button>
          </a>
        </div>
      </div>
    );
  }
}

export default PageNotFound;