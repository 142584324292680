import React, { Component } from 'react';
import * as constants from '../../constants';
import '../../public/css/catering.css';
import MenuEntry from '../MenuEntry';
import { MetaTags } from 'react-meta-tags';

import AppetizerMenu from '../../public/assets/menus/catering/Appetizers.pdf'
import BoxedMenu from '../../public/assets/menus/catering/Boxed.pdf';
import BreakfastMenu from '../../public/assets/menus/catering/Breakfast.pdf';
import BuffetMenu from '../../public/assets/menus/catering/Buffet.pdf';
import CarryoutMenu from '../../public/assets/menus/catering/CarryOut.pdf';
import CateringMenu from '../../public/assets/menus/catering/Catering.pdf';
import DessertsMenu from '../../public/assets/menus/catering/Desserts.pdf';
import PicnicMenu from '../../public/assets/menus/catering/Picnic.pdf';
import PlatedMenu from '../../public/assets/menus/catering/Plated.pdf';
import SandwichesMenu from '../../public/assets/menus/catering/Sandwiches.pdf';
import FuneralMenu from '../../public/assets/menus/catering/Funeral.pdf';

import AppetizerMenuImage from '../../public/img/AppetizerMenuImage.webp';
import BoxedMenuImage from '../../public/img/BoxedMenuImage.webp';
import BreakfastMenuImage from '../../public/img/BreakfastMenuImage.webp';
import BuffetMenuImage from '../../public/img/BoxedMenuImage.webp';
import CarryoutMenuImage from '../../public/img/CarryOutMenuImage.webp';
import CateringMenuImage from '../../public/img/CateringMenuImage.webp';
import DessertsMenuImage from '../../public/img/DessertsMenuImage.webp';
import PicnicMenuImage from '../../public/img/PicnicMenuImage.webp';
import PlatedMenuImage from '../../public/img/PlatedMenuImage.webp';
import SandwichesMenuImage from '../../public/img/SandwichesMenuImage.webp';
import FuneralMenuImage from '../../public/img/FuneralMenuImage.webp';


class CateringPage extends Component {

  constructor() {
    super();
    this.state = {
      // isBreakfast: true,
      // currentMenu: "breakfast"
    };
    // this.switchMenuType = this.switchMenuType.bind(this);
  }

  render() {

    return (
      <div className="page">
        <MetaTags>
          <title>Catering Menus - The Mint Café</title>
          <meta property="og:title" content="Catering Menus - The Mint Café" />
          <meta property="og:description" content="Looking for someone to cater your next event? The Mint has a menu that fits almost any need!" />
          <meta name="description" content="Looking for someone to cater your next event? The Mint has a menu that fits almost any need!" />
          <meta name="keywords" content="restaurant,Wausau,eat,cater,wedding,lunch,breakfast,dinner,business,catering,events" />
          <meta name="googlebot" content="index, follow" />
          <link rel="canonical" content="https://www.mintcafewausau.com/catering" />
        </MetaTags>
        <div className="flex-row" id="catering-menu-wrapper">
          <MenuEntry to={AppetizerMenu}
            title={constants.AppetizerTitle}
            text={constants.AppetizerText}
            img={AppetizerMenuImage}
          />
          <MenuEntry to={BoxedMenu}
            title={constants.BoxedTitle}
            text={constants.BoxedText}
            img={BoxedMenuImage}
          />
          <MenuEntry to={BreakfastMenu}
            title={constants.BreakfastTitle}
            text={constants.BreakfastText}
            img={BreakfastMenuImage}
          />
          <MenuEntry to={BuffetMenu}
            title={constants.BuffetTitle}
            text={constants.BuffetText}
            img={BuffetMenuImage}
          />
          <MenuEntry to={CarryoutMenu}
            title={constants.CarryoutTitle}
            text={constants.CarryoutText}
            img={CarryoutMenuImage}
          />
          <MenuEntry to={DessertsMenu}
            title={constants.DessertsTitle}
            text={constants.DessertsText}
            img={DessertsMenuImage}
          />
          <MenuEntry to={PicnicMenu}
            title={constants.PicnicTitle}
            text={constants.PicnicText}
            img={PicnicMenuImage}
          />
          <MenuEntry to={PlatedMenu}
            title={constants.PlatedTitle}
            text={constants.PlatedText}
            img={PlatedMenuImage}
          />
          <MenuEntry to={SandwichesMenu}
            title={constants.SandwichesTitle}
            text={constants.SandwichesText}
            img={SandwichesMenuImage}
          />
          <MenuEntry to={FuneralMenu}
            title={constants.FuneralTitle}
            text={constants.FuneralText}
            img={FuneralMenuImage}
          />
          <MenuEntry to={CateringMenu}
            title={constants.CateringTitle}
            text={constants.CateringText}
            img={CateringMenuImage}
          />
        </div>
      </div>
    );
  }

}

export default CateringPage;