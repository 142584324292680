import React from 'react';
// import '../../public/css/home.css';
import '../../public/css/home.css';
import * as constants from '../../constants.js'
import logo from '../../public/img/Mint Logo.webp'
import giftcard from '../../public/img/GiftCard.webp'
import HoursEntry from '../HoursEntry';
import MetaTags from 'react-meta-tags';

class HomePage extends React.Component {

  render() {
    return (
      <div className="page">
        <MetaTags>
          <title>The Mint Café in Wausau, WI</title>
          <meta property="og:title" content="The Mint Cafe in Wausau, WI" />
          <meta property="og:description" content="The Mint Cafe in Wausau WI serving Breakfast, Lunch and Dinner." />
          <meta name="description" content="The Mint Cafe in Wausau WI serving Breakfast, Lunch and Dinner." />
          <meta name="keywords" content="restaurant,Wausau,eat,cater,wedding,lunch,breakfast,dinner,business,catering,events" />
          <meta name="googlebot" content="index, follow" />
          <link rel="canonical" content="https://www.mintcafewausau.com" />
        </MetaTags>
        {/* <div id="notice">
          <span>git 
            <font className="strong">Our Response to COVID-19</font>
          </span>
          <br />
          <span>
            In order to protect the health of all our customers, we are offering limited service at this time.<br />
            We are open for in-house service with the <a href="/menus">menus</a> posted here on our website.
            <br />
            We will be limiting seating in observance of social distancing, and wait times may vary.
            <br />
            Carryout still available - call <a href={"tel:" + constants.RestaurantPhone}>{constants.RestaurantPhone}</a>
            <br /><br />
            Thank you for your continued support, we appreciate it greatly!
            <br />
            &nbsp;
          </span>
        </div> */}
        <div id="page-content" className="flex-column">
          <span id="page-title" className="page-title">The Mint Café! Serving Breakfast, Lunch, And Dinner!</span>
          <span id="delivery_notice">
            Deliveries available to the downtown area!
            <br />
            Call ahead for carryouts. <a href={"tel:" + constants.RestaurantPhone}>{constants.RestaurantPhone}</a>
          </span>
          <span id="catering_notice" className="page-title">Catering Available!</span>
          <div id="ContactInfo" className="flex-row">
            <div id="LocationInfo" className="flex-column" width="50%">
              <div id="LocationInfo_Title">We are located at:</div>
              <div id="LocationInfo_Address">
                <a href='/location'>
                  <span>{constants.Address.street}</span>
                  <br />
                  <span>{constants.Address.city}, {constants.Address.state}, {constants.Address.zip}</span>
                </a>
              </div>
            </div>
            <div id="PhoneInfo" className="flex-column" width="50%">
              <div id="PhoneInfo_Title">Call us at:</div>
              <div id="PhoneInfor_Numbers">
                <span>Restaurant: <a href={"tel:" + constants.RestaurantPhone}>{constants.RestaurantPhone}</a></span>
                <br />
                <span>Catering: <a href={"tel:" + constants.CateringPhone}>{constants.CateringPhone}</a></span>
              </div>
            </div>
          </div>
          <div id="hours_and_logo" className="flex-row">
            <div id="hours">
              <div id="hours-title">Open Hours:</div>
              <table>
                <tbody>
                  <HoursEntry day="Monday" />
                  <HoursEntry day="Tuesday" />
                  <HoursEntry day="Wednesday" />
                  <HoursEntry day="Thursday" />
                  <HoursEntry day="Friday" />
                  <HoursEntry day="Saturday" />
                  <HoursEntry day="Sunday" />
                </tbody>
              </table>
            </div>
            <div id="logo">
              <img src={logo} alt="The Mint Cafe Logo" />
            </div>
          </div>
          <div id="giftcard_promotion" className="flex-row">
            <a href="/shop">
              <div id="giftcard_image">
                <img src={giftcard} alt="The Mint Cafe Gift Card" />
              </div>
            </a>
            <div id="giftcard_text">
              <span>
                Need a gift for someone special?
                <br />
                Now you can order gift cards right here!
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default HomePage;