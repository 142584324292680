import React from 'react';
import '../../public/css/shop.css';
import { MetaTags } from 'react-meta-tags';

import GiftCardImg from '../../public/img/GiftCard.webp';

class ShopPage extends React.Component {
  render() {
    return (
      <div className="page flex-row">
        <MetaTags>
          <title>Shop - The Mint Café</title>
          <meta property="og:title" content="Shop - The Mint Cafe" />
          <meta property="og:description" content="Purchase gift cards for The Mint Cafe online" />
          <meta name="description" content="Purchase gift cards for The Mint Cafe online" />
          <meta name="keywords" content="shop,restaurant,Wausau,eat,gift card,wedding,lunch,breakfast,dinner,business,catering,events" />
          <meta name="googlebot" content="index, follow" />
          <link rel="canonical" content="https://www.mintcafewausau.com/shop" />
        </MetaTags>
        <div>
          <img id="GiftCardImg" alt="A Gift Card" border="0" src={GiftCardImg} />
        </div>
        <div id="FormTable">
          <table border="0" cellpadding="3" cellspacing="1">
            <tbody>
              <tr>
                <td colspan="2">
                  <span class="catalogTitle">
                    <strong>Purchase a Gift Card:</strong>
                  </span>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                <form id="paypal_form" action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="PWNKFWU3YAEZ2" />
                  <table>
                    <tr>
                      <td>
                        <input type="hidden" name="on0" value="Value"/>
                        Value
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <select name="os0" id="ValueSelect">
                          <option value="$10 Gift Card">
                            $10 Gift Card
                          </option>
                          <option value="$25 Gift Card">
                            $25 Gift Card
                          </option>
                          <option value="$50 Gift Card">
                            $50 Gift Card
                          </option>
                          <option value="$100 Gift Card">
                            $100 Gift Card
                          </option>
                        </select>
                      </td>
                    </tr>
                  </table>
                  <input type="hidden" name="currency_code" value="USD" />
                  <input type="hidden" name="business" value="mint@mintcafewausau.com"></input>
                  <input alt="Add to cart" border="0" name="submit" src="https://www.paypalobjects.com/en_US/i/btn/btn_cart_LG.gif" type="image" id="paypal-submit-btn" />
                </form>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default ShopPage;
