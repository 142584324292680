import React from 'react';
import '../../public/css/map.css';
import { MetaTags } from 'react-meta-tags';

class MapPage extends React.Component {
  render() {
    return (
      <div className="page">
        <MetaTags>
          <title>Find Us - The Mint Café in Wausau, WI</title>
          <meta property="og:title" content="Find Us - The Mint Cafe in Wausau, WI" />
          <meta property="og:description" content="If you don't know where The Mint is, locate it on the map here" />
          <meta name="description" content="If you don't know where The Mint is, locate it on the map here" />
          <meta name="keywords" content="restaurant,Wausau,eat,cater,wedding,lunch,breakfast,dinner,business,catering,events" />
          <meta name="googlebot" content="index, follow" />
        </MetaTags>
        <div className="text-center" id="business-map">
          <iframe title="Google Map" loading="lazy" scroll="auto" src="https://www.google.com/maps/embed/v1/place?key=AIzaSyB0k3k0IBXOG0aZ7GR58rPfxO6nRLeWoIY&amp;q=422+3rd+St+Wausau+WI+54403">
          </iframe>
        </div>
      </div>
    );
  }
}

export default MapPage;