//Constants for the Menus Page
export const dinnerMenu = "Lunch and Dinner Menu";
export const breakfastMenu = "Breakfast Menu";
export const confirmBreakfast = "The items on our Breakfast menu are not served after 11 AM. Woud you like to proceed anyways?"
export const confirmDinner = "The items on our Lunch and Dinner menu are not served before 11 AM. Woud you like to proceed anyways?"

//Catering Menu Titles and Descriptions
export const AppetizerTitle = "Appetizers";
export const BoxedTitle = "Boxed Meals";
export const BreakfastTitle = "Breakfast";
export const BuffetTitle = "Traditional Buffet";
export const CateringTitle = "View Full Menu";
export const CarryoutTitle = "Carry Out";
export const DessertsTitle = "Desserts";
export const PicnicTitle = "Picnic";
export const PlatedTitle = "Plated Dinners";
export const SandwichesTitle = "Sandwiches and Boxed Lunches";
export const FuneralTitle = "Funeral Buffets";

export const AppetizerText = "Our catering appetizers";
export const BoxedText = "Hearty meals served individually";
export const BreakfastText = "Our breakfast options";
export const BuffetText = "Buffet style menu";
export const CateringText = "Our entire catering menu"
export const CarryoutText = "Take out catering";
export const DessertsText = "Sweet treats for your event";
export const PicnicText = "Outdoor picnic menu";
export const PlatedText = "Plate style entrees";
export const SandwichesText = "Wraps, sandwiches, and more";
export const FuneralText = "Specialty menu for funerals, wakes, and memorials";

//Contact information
export const RestaurantPhone = "715‑845‑5879";
export const CateringPhone = "715‑846‑8805";
export const Address = {
    business: "The Mint Café",
    street: "422 N 3rd Street",
    city: "Wausau",
    state: "WI",
    zip: "54403"
}

//Operating hours
export const hours = {
    Monday: null,
    Tuesday: null,
    Wednesday: null,
    Thursday: null,
    Friday: null,
    Saturday: "7:00 am - 3:00 pm",
    Sunday: "Closed",
    Default: "6:30 am - 8:00 pm"
}

export const contactform = {
    firstname: "First Name *",
    lastname: "Last Name *",
    email: "Email *",
    phone: "Phone",
    message: "Message *",
    success: "Thank you for reaching out - Your response has been recorded! You should hear back from us soon!",
    failure: "We were unable to process your request. Please try again later"
}